<script lang="ts">
import axios from 'axios';
import { defineComponent } from 'vue';

import {
  Item,
  Template,
  Conversion,
  ElementsOfAdditional
} from '@/interfaces/interfaces';

export default defineComponent({
    props: {
        selectedSection: {
            type: String,
            required: true
        },
        elements_of_additional: {
            type: Object as () => ElementsOfAdditional,
            required: true
        }
    },

    emits: ['addChat', 'addTemplate', 'addConversion', 'selectChat', 'deleteChat', 'deleteTemplate', 'deleteConversion', 'selectTemplate', 'selectConversion', 'renameChat', 'renameTemplate', 'renameConversion'],

    setup(props, {emit}: any) {
        const addChat = (section: string) => {
            emit('addChat', section)
        }

        const addTemplate = (section: string) => {
            emit('addTemplate', section)
        }

        const addConversion = (section: string) => {
            emit('addConversion', section)
        }

        const renameChat = (element: Item) => {
            console.log(element.name)
            emit('renameChat', element.chat_id, element.name)
            // if (newName !== null) {
            //     element.name = newName
            // }
        }

        const renameConversion = (element: Conversion) => {
            console.log(element.name)
            emit('renameConversion', element.conversion_id, element.name)
        }

        const renameTemplate = (element: Template) => {
            console.log(element.name)
            emit('renameTemplate', element.template_id, element.name)
        }

        const selectChat = (element: Item) => {
            emit('selectChat', element.chat_id, props.selectedSection)
        }

        const selectTemplate = (element: Template) => {
            emit('selectTemplate', element.template_id, props.selectedSection)
        }

        const selectConversion = (element: Conversion) => {
            emit('selectConversion', element.conversion_id, props.selectedSection)
        }

        const deleteChat = (element: Item) => {
            emit('deleteChat', element.chat_id, props.selectedSection)
        }

        const deleteTemplate = (element: Template) => {
            emit('deleteTemplate', element.template_id, props.selectedSection)
        }

        const deleteConversion = (element: Conversion) => {
            emit('deleteConversion', element.conversion_id, props.selectedSection)
        }

        return { addChat , addTemplate, addConversion , selectChat , deleteChat, deleteTemplate, deleteConversion, selectTemplate, selectConversion, renameChat, renameTemplate, renameConversion }// {.., .., ..};
    }
})

</script>


<template>
<div class="container-fluid p-0" id="additional-section">
    <div v-if="selectedSection === 'chats'" class="container-fluid p-0" id="background">
        <!-- Хедер -->
        <div class="d-flex justify-content-between align-items-center ps-3 pe-3 pt-2" id="header">
            <p class="text m-0 pe-7">Список сессий</p>
            <i @click="addChat('chats')" class="d-block fa-solid fa-circle-plus" style="font-size: 1.5rem;"></i>
        </div>

        <!-- Список чатов -->
        <div class="w-100 container-fluid p-0" id="chats-list">
            <div v-for="element in elements_of_additional['chats']" @click="selectChat(element as Item)" :class="{'active': element.active === true}" class="chat element p-0 d-flex justify-content-between align-items-center">
                <p class="chat-text m-0 text-truncate" style="max-width: 200px;">{{ (element as Item).name }}</p>
                <div class="d-flex">
                    <i data-bs-toggle="modal" :data-bs-target="'#'+(element as Item).chat_id" class="fa-solid fa-pencil me-2" style="font-size: 0.9rem;"></i>
                    <i @click="deleteChat(element as Item)" class="fa-regular fa-trash-can me-2" style="font-size: 0.9rem;"></i>
                </div>
                <!-- Модальное окно -->
                <div class="modal fade" :id="(element as Item).chat_id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Измените имя элемента</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
                            </div>
                        <div class="modal-body">
                            <textarea class="container-fluid" v-model="(element as Item).name"></textarea>
                        </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                                <button type="button" class="btn btn-primary" @click="renameChat(element as Item)" data-bs-dismiss="modal">Сохранить изменения</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


    <div v-if="selectedSection === 'templates'" class="container-fluid p-0" id="background">
        <!-- Хедер -->
        <div class="d-flex justify-content-between align-items-center ps-3 pe-3 pt-2" id="header">
            <p class="text m-0 pe-7">Сохраненные шаблоны</p>
            <i @click="addTemplate('templates')" class="d-block fa-solid fa-circle-plus" style="font-size: 1.5rem;"></i>
        </div>

        <!-- Список шаблонов -->
        <div class="w-100 container-fluid p-0" id="chats-list">
            <div v-for="element in elements_of_additional['templates']" @click="selectTemplate(element as Template)" :class="{'active': element.active === true}" class="chat element p-0 d-flex justify-content-between align-items-center">
                <p class="chat-text m-0 text-truncate" style="max-width: 200px;">{{ 'Шаблон' + ' ' + (element as Template).name }}</p>
                <div class="d-flex">
                    <i data-bs-toggle="modal" :data-bs-target="'#'+(element as Template).template_id" class="fa-solid fa-pencil me-2" style="font-size: 0.9rem;"></i>
                    <i @click="deleteTemplate(element as Template)" class="fa-regular fa-trash-can me-2" style="font-size: 0.9rem;"></i>
                </div>
                <!-- Модальное окно -->
                <div class="modal fade" :id="(element as Template).template_id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Измените имя элемента</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
                            </div>
                        <div class="modal-body">
                            <textarea class="container-fluid" v-model="(element as Template).name"></textarea>
                        </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                                <button type="button" class="btn btn-primary" @click="renameTemplate(element as Template)" data-bs-dismiss="modal">Сохранить изменения</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div v-if="selectedSection === 'conversions'" class="container-fluid p-0" id="background">
        <!-- Хедер -->
        <div class="d-flex justify-content-between align-items-center ps-3 pe-3 pt-2" id="header">
            <p class="text m-0 pe-7">Хранилище конвертаций</p>
            <!-- <i @click="addConversion('conversions')" class="d-block fa-solid fa-circle-plus" style="font-size: 1.5rem;"></i> -->
        </div>

        <!-- Список сохраненных конвертаций -->
        <div class="w-100 container-fluid p-0" id="chats-list">
            <div v-for="element in elements_of_additional['conversions']" @click="selectConversion(element as Conversion)" :class="{'active': element.active === true}" class="chat element p-0 d-flex justify-content-between align-items-center">
                <p class="chat-text m-0 text-truncate" style="max-width: 200px;"> {{ (element as Conversion).name }} </p>
                <div class="d-flex">
                    <i data-bs-toggle="modal" :data-bs-target="'#'+(element as Conversion).conversion_id" class="fa-solid fa-pencil me-2" style="font-size: 0.9rem;"></i>
                    <i @click="deleteConversion(element as Conversion)" class="fa-regular fa-trash-can me-2" style="font-size: 0.9rem;"></i>
                </div>
                <!-- Модальное окно -->
                <div class="modal fade" :id="(element as Conversion).conversion_id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Измените имя элемента</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
                            </div>
                        <div class="modal-body">
                            <textarea class="container-fluid" v-model="(element as Conversion).name"></textarea>
                        </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                                <button type="button" class="btn btn-primary" @click="renameConversion(element as Conversion)" data-bs-dismiss="modal">Сохранить изменения</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<style scoped>
#additional-section {
    height: 100%;
    width: 14%;
    /* width: 350px; */
}

#background {
    height: 100%;
    background-color: #2B2B31;
}

.text {
    color: rgb(254, 255, 247);
    font-size: 1.2rem;
}

.element {
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    border-radius: 6px;
    background-color: #393944;
    transition: 0.3s;
}

.element.active {
    background-color: #5E5E6F;
}

.element:hover {
    background-color: #5E5E6F;
    cursor: pointer;
}

.chat-text {
    font-size: 0.8rem;
    padding-left: 10px;
    /* padding-bottom: 3px; */
    color:rgb(254, 255, 247);
}

i {
    color: #cacaca;
    transition: 0.3s;
    cursor: pointer;
}

i:hover {
    color: white;
}
</style>