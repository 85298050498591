<script lang="ts">
import { ref , watch, nextTick , defineComponent } from 'vue';
import axios from 'axios';

import {
  Inputs,
  Conversion,
  ElementsOfAdditional,
  Template
} from '@/interfaces/interfaces';

interface Props {
  selectedSection: string;
  elements_of_additional: ElementsOfAdditional;
  needSpinner: boolean
  customTechnic: string
}

export default defineComponent ({
    props: {
        selectedSection: {
            type: String,
            required: true
        },
        elements_of_additional: {
            type: Object as () => ElementsOfAdditional,
            required: true
        },
        needSpinner: {
            type: Boolean,
            required: true
        },
        customTechnic: {
            type: String,
            required: true
        }
    },

    emits: ['updateSystemPrompt', 'selectLLM', 'updateInputs', 'setTechnic', 'updateSubjectArea', 'updateContext', 'rememberHistory'],

    setup(props: Props, {emit}: any) {

        let systemPrompt = ref('')
        let currentTechnic = ref('custom')
        let subjectArea = ref('')
        let inputs = ref<Inputs>({
            'singleChoice': {
                question: 0,
                answer: 2
            },
            'multiplyChoice': {
                question: 0,
                answer: 2
            },
            'yesNo': {
                question: 0,
                answer: 2
            },
            'sort': {
                question: 0,
                answer: 2
            },
            'match': {
                question: 0,
                answer: 2
            },
            'manualEnter': {
                question: 0,
                answer: 1
            }
        })
        let defaultInputs = JSON.parse(JSON.stringify(inputs.value));

        const fileContent = ref<string>('');
        let result_of_convert = ref('')
        let selectedLLM = ref('empty')
        let needHistory = ref<boolean>(false)



        const setTechnic = (technic: string) => {
            emit('setTechnic', technic)
            currentTechnic.value = technic
    
        }

        const changeSubjectArea = () => {
            emit('updateSubjectArea', subjectArea.value)
        }


        // Функция обработки загрузки файла
        const handleFileUpload = async (event: any) => {
            const target = event.target;
            const file = target.files?.[0];
            if (file) {
                const fileType = file.type;
                if (fileType !== 'text/plain' && fileType !== 'application/pdf') {
                    alert('Пожалуйста, загрузите текстовый файл с расширением .txt или PDF-файл.');
                    return;
                }

                if (file.size > 5 * 1024 * 1024) {
                    alert('Файл должен быть размером не более 5MB');
                    return;
                }

                if (fileType === 'text/plain') {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        fileContent.value = e.target?.result as string;
                        target.value = '';
                    };
                    reader.readAsText(file);
                } else if (fileType === 'application/pdf') {
                    try {
                        let path = file.path
                        await axios.post('/api/templates/getPdfText', {path: path})
                            .then(response => {
                                fileContent.value = response.data.data
                            })
                            .catch(error => {
                                console.log('Error: ', error)
                            })

                        target.value = '';
                    } catch (error) {
                        console.error('Ошибка при чтении PDF-файла:', error);
                    }
                }
            }
        };

        const deleteFileContent = () => {
            fileContent.value = '';
        }

        const selectLLM = (event: any) => {
            selectedLLM.value = event.target.value
            emit('selectLLM', event.target.value)
        }


        const downloadResult = (result: string) => {
            const blob = new Blob([result], { type: 'text/xml' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'converted_result.xml';
            link.click();
            URL.revokeObjectURL(url);
        }

        const rememberHistory = () => {
            emit('rememberHistory', needHistory.value)
        }

        watch(systemPrompt, (newValue, oldValue) => {
            systemPrompt.value = newValue
            emit('updateSystemPrompt', systemPrompt.value)
        })

        watch(inputs, (newValue, oldValue) => {
            // inputs.value = newValue
            emit('updateInputs', inputs.value, currentTechnic.value)
        }, { deep: true })

        watch(currentTechnic, (newValue, oldValue) => {
            currentTechnic.value = newValue
            emit('updateInputs', inputs.value, currentTechnic.value)
        })

        watch(
            () => props.customTechnic,
            () => {
                nextTick(()=> {
                    currentTechnic.value = props.customTechnic
                })
            }
        )

        watch(fileContent, (newValue, oldValue) => {
            fileContent.value = newValue
            console.log('fileContent.value = ',fileContent.value)
            emit('updateContext', fileContent.value)
        })



        watch(
            () => props.elements_of_additional[props.selectedSection],
            () => {
                nextTick(() => {
                    if (props.selectedSection === 'templates') {
                        const activeElement = props.elements_of_additional['templates'].find(template => template.active);
                        if (activeElement) {
                            inputs.value = JSON.parse((activeElement as Template).inputs_object)
                            currentTechnic.value = (activeElement as Template).technic
                            console.log('ПЕРЕКЛЮЧИЛ ШАБЛОН, currentTechnic.value = ' + currentTechnic.value)
                            subjectArea.value = (activeElement as Template).subject_area
                            fileContent.value = (activeElement as Template).context
                        } else {
                            subjectArea.value = ''
                            fileContent.value = ''
                            currentTechnic.value = ''
                            inputs.value = JSON.parse(JSON.stringify(defaultInputs));
                        }
                    } else if (props.selectedSection === 'conversions') {
                        const activeConversion = props.elements_of_additional['conversions'].find(conversion => conversion.active);
                        if (activeConversion) {
                            result_of_convert.value = (activeConversion as Conversion).result
                        } else {
                            result_of_convert.value = ''
                        }
                    }
                })
            }
        )



        return {
            selectedLLM,
            systemPrompt,
            selectLLM,
            inputs,
            setTechnic,
            currentTechnic,
            subjectArea,
            changeSubjectArea,
            handleFileUpload,
            fileContent,
            deleteFileContent,
            result_of_convert,
            downloadResult,
            rememberHistory,
            needHistory
            // selectOptionTemplate
        }// {.., .., ..};
    }
})

</script>


<template>
<!-- Если выбраны чаты -->
<div v-if="selectedSection === 'chats'" class="container-fluid p-0" id="rightside-section-chats">
    <div class="container-fluid p-0" id="background">
        <div id="header" class="d-flex justify-content-center align-items-center">
            <p class="m-0">Настройки</p>
        </div>

        <div class="items_of_settings container-fluid d-flex flex-column p-0 align-items-center" id="set_system_prompt">
            <div id="header_of_system_prompt" class="container-fluid position-relative d-flex align-items-center p-0">
                <p>Системный промпт</p>
            </div>
            <div id="system_prompt" class="container-fluid p-0 d-flex align-items-center">
                <textarea class="container-fluid p-0" id="textarea_for_system_prompt" v-model="systemPrompt"></textarea>
            </div>
        </div>

        <div class="items_of_settings container-fluid d-flex flex-column p-0 align-items-center position-relative" id="select_llm">
            <select v-model="selectedLLM" @change="selectLLM($event)" class="form-select form-select-sm" aria-label=".form-select-lg example">
                <option value="empty" >Выбор LLM...</option>
                <option value="gpt-3.5">GPT-3.5</option>
                <option value="gpt-4">GPT-4</option>
                <option value="gigachat">GigaChat</option>
                <option value="mistral">Mistral Tiny</option>
                <option value="yandexgpt">YandexGPT</option>
            </select>
            <i class="fa-solid fa-box-open position-absolute" style="color: #ffffff;"></i>
        </div>

        <div class="items_of_settings container-fluid d-flex flex-column p-0 align-items-center" id="set_parameters">
            <div id="header_of_parameters" class="container-fluid position-relative d-flex align-items-center p-0">
                <p>Настройка параметров</p>
                <i class="fa-solid fa-screwdriver-wrench position-absolute align-middle" style="color: #ffffff;"></i>
            </div>
            <div id="parameters" class="container-fluid p-0">
                <div class="container-fluid p-0 d-flex justify-content-between">
                    <p>Креативность</p>
                    <input type="number" min="0" max="1" step="0.1" value="0.3" class="llm_parameter">
                </div>
                <div class="container-fluid p-0 d-flex justify-content-between">
                    <p>Max tokens</p>
                    <input type="number" min="16" step="16" value="4096" max="8192" class="llm_parameter">
                </div>
            </div>
        </div>
        <div class="form-check container-fluid items_of_settings pe-0" id="remember_history">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="needHistory" @change="rememberHistory">
            <label class="form-check-label" for="flexCheckChecked">
                Запоминать историю генераций в данной сессии
            </label>
        </div>
    </div>
</div>
<!-- Если выбраны чаты -->


<!-- Если выбраны шаблоны -->
<div v-if="selectedSection === 'templates'" class="container-fluid p-0" id="rightside-section-templates">
    <div class="container-fluid p-0" id="background">
        <div id="header" class="d-flex justify-content-center align-items-center">
            <p class="m-0">Настройки</p>
        </div>
        <div class="container-fluid items_of_settings d-flex flex-column align-items-center">
            <div class="row container-fluid">
                <div class="col-8">
                    <table class="table setting-questions" id="select-questions">
                        <thead>
                            <tr class="text-center">
                                <th scope="col" class="text-start">Тип вопроса</th>
                                <th scope="col">Количество вопросов</th>
                                <th scope="col">Варианты ответов</th>
                            </tr>
                        </thead>
                        <tbody class="text-center">
                            <tr>
                                <td class="text-start">Единственный выбор:</td>
                                <td class="text-center input-td"><input type="number" v-model="inputs.singleChoice.question" min="0" max="5" :disabled="currentTechnic === 'custom' || currentTechnic === ''" class="count-question"></td>
                                <td class="text-center input-td"><input type="number" v-model="inputs.singleChoice.answer" min="2" max="5" :disabled="currentTechnic === 'custom'" class="count-answer"></td>
                            </tr>
                            <tr>
                                <td class="text-start">Множественный выбор:</td>
                                <td class="text-center input-td"><input type="number" v-model="inputs.multiplyChoice.question" min="0" max="5" :disabled="currentTechnic === 'custom' || currentTechnic === ''" class="count-question"></td>
                                <td class="text-center input-td"><input type="number" v-model="inputs.multiplyChoice.answer" min="2" max="5" :disabled="currentTechnic === 'custom' || currentTechnic === ''" class="count-answer"></td>
                            </tr>
                            <tr>
                                <td class="text-start">Да/Нет:</td>
                                <td class="text-center input-td"><input type="number" v-model="inputs.yesNo.question" min="0" max="5" :disabled="currentTechnic === 'custom' || currentTechnic === ''" class="count-question"></td>
                                <td class="text-center input-td"><input type="number" v-model="inputs.yesNo.answer" min="2" max="2" :disabled="currentTechnic === 'custom' || currentTechnic === ''" class="count-answer"></td>
                            </tr>
                            <tr>
                                <td class="text-start">Упорядочивание:</td>
                                <td class="text-center input-td"><input type="number" v-model="inputs.sort.question" min="0" max="5" :disabled="currentTechnic === 'custom' || currentTechnic === ''" class="count-question"></td>
                                <td class="text-center input-td"><input type="number" v-model="inputs.sort.answer" min="2" max="5" :disabled="currentTechnic === 'custom' || currentTechnic === ''" class="count-answer"></td>
                            </tr>
                            <tr>
                                <td class="text-start">Соответствие:</td>
                                <td class="text-center input-td"><input type="number" v-model="inputs.match.question" min="0" max="5" :disabled="currentTechnic === 'custom' || currentTechnic === ''" class="count-question"></td>
                                <td class="text-center input-td"><input type="number" v-model="inputs.match.answer" min="2" max="5" :disabled="currentTechnic === 'custom' || currentTechnic === ''" class="count-answer"></td>
                            </tr>
                            <tr>
                                <td class="text-start">Текстовый ответ:</td>
                                <td class="text-center input-td"><input type="number" v-model="inputs.manualEnter.question" min="0" max="5" :disabled="currentTechnic === 'custom' || currentTechnic === ''" class="count-question"></td>
                                <td class="text-center input-td"><input type="number" v-model="inputs.manualEnter.answer" min="1" max="5" :disabled="currentTechnic === 'custom' || currentTechnic === ''" class="count-answer"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-4 d-flex flex-column">
                    <div class="items_of_settings mt-0 mb-3 container d-flex flex-column p-0 align-items-center flex-grow-1" id="set_topic">
                        <div id="header_of_subject_area" class="container-fluid position-relative d-flex align-items-center p-0">
                            <p>Предметная область</p>
                        </div>
                        <div id="subject_area_bg" class="container-fluid p-0 d-flex align-items-center h-100">
                            <textarea class="container-fluid p-0" id="textarea_for_subject_area" @input="changeSubjectArea" v-model="subjectArea" :disabled="currentTechnic === 'custom' || currentTechnic === ''"></textarea>
                        </div>
                    </div>
                    <div id="button-load-context"onclick="document.getElementById('fileInput').click()" class="container-fluid" :class="currentTechnic === '' ? 'disabled' : ''"><!-- @click="openFileDialog" -->
                        <p class="p-2 text-center m-0">Загрузить контекст</p>
                    </div>
                    <div v-if="fileContent !== ''" class="container-fluid d-flex">
                        <div class="mt-2" id="fa-trash-can-id"><i @click="deleteFileContent" class="fa-regular fa-trash-can" style="font-size: 0.9rem; color: #a82323;"></i></div>
                        <div class="container-fluid mt-2" style="color: gray; font-size: 0.8rem;">Удалить контекст</div>
                    </div>
                    <!-- Скрытый элемент input для загрузки файлов -->
                   <input type="file" id="fileInput" ref="fileInput" style="display: none;" @change="handleFileUpload" accept=".txt,.pdf">
                    <div class="container-fluid d-flex">
                        <div class="mt-2"><i class="fa-solid fa-circle-info" style="color: gray;"></i></div>
                        <div v-if="fileContent === ''" class="container-fluid mt-2" style="color: gray; font-size: 0.8rem;">Загрузка контекста предметной области позволит моделям генерировать вопросы, не выходя за пределы области знаний.</div>
                        <div v-if="fileContent !== ''" class="container-fluid mt-2" style="color: gray; font-size: 0.8rem;">Контекст загружен. Для смены контекста загрузите другой файл.</div>
                    </div>
                </div>
            </div>
            <div class="row container-fluid mt-4">
                <div class="col-5 d-flex flex-column">
                    <div class="p-0 bg-custom">
                        <table class="table mb-0 setting-questions" id="select-technique">
                            <thead>
                                <tr class="text-center">
                                    <th scope="col" class="text-start">Техника промптинга</th>
                                </tr>
                            </thead>
                            <tbody class="text-center">
                                <tr>
                                    <td :class="{ active: currentTechnic === 'zeroshot', disabled: currentTechnic === '' }" class="text-start" @click="setTechnic('zeroshot')">Zero-shot</td>
                                </tr>
                                <tr>
                                    <td :class="{ active: currentTechnic === 'fewshot', disabled: currentTechnic === '' }" class="text-start" @click="setTechnic('fewshot')">Few-shot</td>
                                </tr>
                                <tr>
                                    <td :class="{ active: currentTechnic === 'custom', disabled: currentTechnic === '' }" class="text-start" @click="setTechnic('custom')">Пользовательская</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-7 container-fluid">
                    <div class="container-fluid d-flex align-items-center" id="header_info_technique">Информация о технике</div>
                    <div class="container-fluid pt-2 pb-2" id="info_technique">
                        <p v-if="currentTechnic === 'fewshot'">Few-shot промптинг позволяет большим языковым моделям давать ответ, не требующий рассуждений, на задачу, предоставляя несколько примеров в запросе. Эта техника используется для обучения моделей на контексте демонстраций, что повышает их производительность в сложных задачах</p>
                        <p v-if="currentTechnic === 'zeroshot'">Техника Zero-Shot позволяет большим языковым моделям выполнять задачи без предварительного обучения на примерах. Это достигается путем предоставления инструкций без дополнительных данных для обучения</p>
                        <p v-if="currentTechnic === 'custom'">Изучайте техники промптинга и создавайте свои собственные шаблоны, выбрав "пользовательскую" технику</p>
                        <p v-if="currentTechnic === ''">Создайте новый или выберите существующий шаблон для взаимодействия с техниками.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Если выбраны шаблоны -->



<!-- Если выбрана конвертация -->
<div v-if="selectedSection === 'conversions'" class="container-fluid p-0" id="rightside-section-conversion">
    <div class="container-fluid p-0 d-flex flex-column justify-content-between" id="background">
        <div class="container-fluid" id="header-of-conversion"></div>
        <div class="container-fluid p-0 d-flex flex-column justify-content-center" id="conversion">
            <div class="conversion_block container-fluid p-0 mb">
                <div class="container-fluid p-0 h-100">
                    <textarea readonly id="textarea-for-conversion" class="container-fluid p-3 pb-5" v-model="result_of_convert"></textarea>
                    <div v-if="$props.needSpinner === true" class="d-flex justify-content-center mt-5">
                        <div class="spinner-border text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div v-if="$props.needSpinner === false" class="d-flex justify-content-center p-0">
                        <div id="button-conversion" class="container-fluid p-0 mt-5" @click="downloadResult(result_of_convert)">
                            <p id="download_text" class="p-2 text-center m-0">Скачать</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Если выбрана конвертация -->


</template>


<style scoped>
.llm_parameter {
    margin: 8px;
    background: rgb(59, 55, 73) !important;
    border-radius: 8px;
    width: 65px;
}

#remember_history {
    width: 90%;
    color: rgb(226, 226, 226);
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}


#download_text {
    color: rgb(0, 0, 0) !important;
}

#button-conversion {
    width: 35%;
    background-color: #eeff2e;
    color: rgb(0, 0, 0) !important;
    border-radius: 20px;
    transition: 0.3s;
}

#button-conversion:hover {
    background-color: #f3ff6b;
    color: rgb(0, 0, 0) !important;
    cursor: pointer;
}

.fa-trash-can:hover {
    color: rgb(255, 0, 0) !important;
    cursor:pointer
}

tr > td.active {
    background-color: #535377 !important;
    /* background-color: #b9b167; */
}

#header-of-conversion {
    height: 40px;
    background-color: black;
}

#textarea-for-conversion {
    resize: none;
    height: 90%;
    background-color: #2E2E36;
    border-radius: 10px;
    color: white;
    border: none;
}

#textarea-for-conversion:focus {
    outline: none;
}

#conversion {
    height: 100%;
}

.conversion_block {
    width: 70%;
    height: 90%;
}

#button-load-context {
    background-color: #71A876;
    color: rgb(235, 235, 235);
    border-radius: 20px;
    transition: 0.3s;
}

#button-load-context:hover {
    background-color: #83c088;
    color: white;
    cursor: pointer;
}

#info_technique {
    background-color: #35354E;
}

#header_info_technique {
    height: 35px;
    background-color: #5C5C9E;
    color: white;
    font-weight: bold;
}

.input-td {
    background-color: #2A2A42 !important;
}

.bg-custom {
    background-color: #35354E;
    margin-bottom: 1rem;
}

input {
    text-align: center;
    border: none;
    color: white;
    background-color: #474766;
}

input:focus {
    outline: none;
}

.setting-questions thead tr th{
    background-color: #5C5C9E !important;
    border: none;
    color: white;
}

.setting-questions tbody tr td {
    background-color: #35354E;
    border: none;
    color: white;
}

.count-question {
    width: 80px;
}

.count-answer {
    width: 80px;
}

#select-questions {
    font-size: small;
    height: 100%;
}

#select-technique {
    font-size: small;
}

#select-technique tbody tr td {
    padding: 13px 0px 13px 15px;
}

#select-technique tbody tr td:hover {
    background-color: #635C81;
    cursor: pointer;
}

#rightside-section-chats {
    height: 100%;
    width: 15%;
    /* width: 330px; */
}

#rightside-section-conversion {
    height: 100%;
    width: 48%;
    /* flex: 1 1 auto */
}

#rightside-section-templates {
    height: 100%;
    width: 40.5%;
    /* width: 1200px; */
}

#background {
    height: 100%;
    background-color: #232328;
}

#header {
    height: 35px;
    background-color: #121213;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.items_of_settings {
    margin-top: 25px;
}

p {
    color: rgb(254, 255, 247);
    margin: 0px;
}

#select_llm select{
    background-color: #5C5C9E;
    border-color: #AFAFEC;
    color: white;
    width: 90%;
    padding-left: 40px;
}

#select_llm select option {
    background-color: #2E2E36;
    color: white;
}

#select_template select {
    background-color: #779EEA;
    border-color: #4574CD;
    color: rgb(3, 3, 3);
    width: 90%;
    padding-left: 40px;
}

#select_template select option {
    background-color: #B2C7EF;
    color: rgb(3, 3, 3);
}

#set_parameters {
    width: 90%;
}

#set_system_prompt {
    width: 90%;
    background-color: #1a1a1b;
    border-radius: 10px;
    height: 15%;
}

#set_topic {
    width: 100%;
    /* height: 100м; */
    background-color: #1a1a1b;
}

#header_of_parameters {
    background-color: #1a1a1b;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#header_of_system_prompt {
    background-color: #1a1a1b;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#header_of_subject_area {
    background-color: #5C5C9E;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#header_of_parameters p {
    padding-left: 40px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: .875rem;
}

#header_of_system_prompt p {
    padding-left: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: .875rem;
}
#header_of_subject_area p {
    padding-left: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: .875rem;
}

#parameters {
    background-color: #2E2E36;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

#system_prompt {
    background-color: #1a1a1b;
    height: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
#subject_area_bg {
    background-color: #35354E;
    height: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

#textarea_for_system_prompt {
    height: 85%;
    width: 94% !important;
    background-color: #2E2E36;
    resize: none;
    border: 1px solid #40406F;
    color: rgb(219, 219, 219);
}

#textarea_for_system_prompt:focus {
    outline: none;
}
#textarea_for_subject_area {
    height: 85%;
    width: 94% !important;
    background-color: #48486e;
    resize: none;
    border: 1px solid #40406F;
    color: rgb(219, 219, 219);
}

#textarea_for_subject_area:focus {
    outline: none;
}

#parameters p {
    padding: 4px 0px 7px 7px;
}

.fa-box-open {
    left:0;
    padding-top: 7px;
    margin-left: 25px;
}

.fa-puzzle-piece {
    left:0;
    padding-top: 7px;
    margin-left: 28px;
}

.fa-screwdriver-wrench {
    left:0;
    margin-left: 13px;
}

</style>
