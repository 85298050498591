<script lang="ts">
import { watch, nextTick, onMounted , ref} from 'vue';
import { defineComponent } from 'vue';


interface Props {
  showModal: boolean;
}

export default defineComponent({
    props: {
      showModal: {
        type: Boolean,
        required: true
      }
    },

    emits: [],
    setup(props: Props, {emit}: any) {
      let modal: any;
      const showModal = ref(true);


      const dontShowAgain = () => {
         // Установите значение show в localStorage в false
         localStorage.setItem('showModal', 'false');
      }

      onMounted(() => {
        const show = localStorage.getItem('showModal');
        if (show === 'false') {
          showModal.value = false;
        } else {
          modal = new (window as any).bootstrap.Modal(document.getElementById('info'));
          if (props.showModal) {
            nextTick(() => {
              modal.show();
            });
          }
        }
      });
      
      watch(
        () => props.showModal,
        (newValue) => {
          nextTick(() => {
            if (newValue && showModal.value) {
              modal.show();
            }
          });
        }
      );

      return { dontShowAgain } // {.., .., ..};
    }
})

</script>

<template>
<!-- Модальное окно -->
<div class="modal fade" id="info" tabindex="-1" aria-labelledby="infoModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="infoModal">Инструкция по использованию приложения</h1>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <div id="carouselExampleIndicators" class="carousel slide">
                  <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" aria-label="Slide 8"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8" aria-label="Slide 9"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="9" aria-label="Slide 10"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="10" aria-label="Slide 11"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="11" aria-label="Slide 12"></button>
                    </div>
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div class="container-fluid mb-4"><p class="text-instruction">Данное приложение нацелено на автоматическую генерацию тестов на основе LLM и их конвертацию в Moodle-совместимый XML формат.<br><br>Для достижения эффективного результата необходимо построить шаблон промпта.<br><br>Перейдите на вкладку "Шаблоны":</p></div>
                      <div class="container-fluid d-flex justify-content-center">
                        <img src="../assets/img/SELECT_TEMPLATE.png" class="d-block w-100" style="max-width: 300px; max-height: 650px;" alt="...">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="container-fluid mb-4"><p class="text-instruction">В правой секции настройте будущий шаблон, установив опционально:<br>– количество необходимых вопросов<br>– количество вариантов ответов<br>- технику промптинга<br>- предметную область (тему)<br>- контекст области (txt/pdf)</p></div>
                      <img src="../assets/img/SET_OPTIONS.png" class="d-block w-100" style="max-height: 950px;" alt="...">
                    </div>
                    <div class="carousel-item">
                      <div class="container-fluid mb-4"><p class="text-instruction">Одновременно с настройкой будет меняться и сам шаблон.<br><br>При необходимости можно отредактировать шаблон самостоятельно, однако сильное изменение может повлиять на конечный результат конвертации.<br><br>Сохраните шаблон:</p></div>
                      <img src="../assets/img/GET_AND_SAVE_TEMPLATE.png" class="d-block w-100" style="max-height: 950px;" alt="...">
                    </div>
                    <div class="carousel-item">
                      <div class="container-fluid mb-4"><p class="text-instruction">Переименуйте шаблон, чтобы в дальнейшем быстро найти его среди других шаблонов:</p></div>
                      <img src="../assets/img/CHANGE_NAME_TEMPLATE.png" class="d-block w-100" style="max-height: 950px;" alt="...">
                    </div>
                    <div class="carousel-item">
                      <div class="container-fluid mb-4"><p class="text-instruction">Перейдём в раздел "Сессии генерации", чтобы использовать построенный шаблон и получить результат:</p></div>
                      <div class="container-fluid d-flex justify-content-center">
                        <img src="../assets/img/SELECT_CHAT.png" class="d-block w-100" style="max-width: 300px; max-height: 650px;" alt="...">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="container-fluid mb-4"><p class="text-instruction">Добавьте новую сессию:</p></div>
                      <img src="../assets/img/ADD_NEW_CHAT.png" class="d-block w-100" style="max-height: 950px;" alt="...">
                    </div>
                    <div class="carousel-item">
                      <div class="container-fluid mb-4"><p class="text-instruction">Выберите любой из доступных построенных шаблонов:</p></div>
                      <img src="../assets/img/SELECT_OPTION_TEMPLATE.png" class="d-block w-100" style="max-height: 950px;" alt="...">
                    </div>
                    <div class="carousel-item">
                      <div class="container-fluid mb-4"><p class="text-instruction">Выберите большую языковую модель:</p></div>
                      <img src="../assets/img/SELECT_LLM.png" class="d-block w-100" style="max-height: 950px;" alt="...">
                    </div>
                    <div class="carousel-item">
                      <div class="container-fluid mb-4"><p class="text-instruction">Задайте системный промпт, чтобы установить роль модели для получения надёжного результата (опционально):</p></div>
                      <img src="../assets/img/SET_SYSTEM_PROMPT.png" class="d-block w-100" style="max-height: 950px;" alt="...">
                    </div>
                    <div class="carousel-item">
                      <div class="container-fluid mb-4"><p class="text-instruction">В поле запроса добавим уточняющие детали (например, попросим не выходить за пределы предоставленного в шаблоне контекста), отправим промпт и получим ответ:</p></div>
                      <img src="../assets/img/GET_RESULT_ANSWER.png" class="d-block w-100" style="max-height: 950px;" alt="...">
                    </div>
                    <div class="carousel-item">
                      <div class="container-fluid mb-4"><p class="text-instruction">Выполним конвертацию результирующего текста в Moodle-совместимый формат XML, нажав на кнопку:</p></div>
                      <img src="../assets/img/CLICK_BUTTON_TO_CONVERT.png" class="d-block w-100" style="max-height: 950px;" alt="...">
                    </div>
                    <div class="carousel-item">
                      <div class="container-fluid mb-4"><p class="text-instruction">Получим сконвертированную XML-разметку, скопируйте или скачайте её:</p></div>
                      <img src="../assets/img/FINAL.png" class="d-block w-100" style="max-height: 950px;" alt="...">
                    </div>
                    <!-- <div class="carousel-item">
                      <img src="..." class="d-block w-100" alt="...">
                    </div> -->
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Предыдущий</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Следующий</span>
                  </button>
                </div>
        </div>
        <div class="modal-footer">
          <button @click="dontShowAgain" type="button" class="btn btn-dark" data-bs-dismiss="modal">Больше не показывать</button>
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-header {
  background-color: #5C5C9E;
  color: white;
  border-bottom: none;
}

.modal-footer, .modal-body {
  border-top: none;
  background-color: #464658;
}

.text-instruction {
  color: white;
}
</style>