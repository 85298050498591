<script lang="ts">
import { ref, defineComponent, watch, nextTick } from 'vue';


interface Props {
    selectedSection: string;
}

export default defineComponent ({
    props: {
        selectedSection: {
            type: String,
            required: true
        }
    },
    emits: ['selectSection'],
    setup(props: Props, {emit}: any) {
        const activeSection = ref('chats'); // Начально активная секция

        const selectSection = (section: string) => {
            emit('selectSection', section);
            activeSection.value = section;
        }

        watch(
            () => props.selectedSection,
            (newValue) => {
                nextTick(() => {
                    activeSection.value = newValue;
                });
            }
        );

        return { selectSection , activeSection} // {.., .., ..};
    }
})

</script>


<template>
<div class="container-fluid p-0 d-flex flex-column justify-content-between" id="leftside-panel">
    <div class="container-fluid p-0" id="background">
        <!-- Кнопка "Профиль" -->
        <!-- <div>
            <div id="profile-button" class="button d-flex align-items-center justify-content-center pt-3 pb-4">
                <i class="left-panel-icon fa-solid fa-user" style="color: #d3d3d3; font-size: 20px;"></i>
            </div>
        </div> -->

        <!-- Кнопка "Чаты" -->
        <div>
            <div @click="selectSection('chats')" id="chats-button" :class="{'active': activeSection === 'chats'}" class="button d-flex align-items-center justify-content-center ms-1 pt-3 pb-3 mt-3">
                <i class="left-panel-icon fa-solid fa-comment" style="color: #ffffff; font-size: 20px"></i>
            </div>
        </div>

        <!-- Кнопка "Шаблоны" -->
        <div>
            <div @click="selectSection('templates')" id="templates-button" :class="{'active': activeSection === 'templates'}" class="button d-flex align-items-center justify-content-center ms-1 mt-3 mb-3 pt-3 pb-3">
                <i class="left-panel-icon fa-solid fa-puzzle-piece" style="color: #d3d3d3; font-size: 20px"></i>
            </div>
        </div>

        <!-- Кнопка "Конвертация" -->
        <div>
            <div @click="selectSection('conversions')" id="conversion-button" :class="{'active': activeSection === 'conversions'}" class="button d-flex align-items-center justify-content-center ms-1 mt-3 mb-3 pt-3 pb-3">
                <i class="left-panel-icon fa-solid fa-file-circle-question" style="color: #d3d3d3; font-size: 20px"></i>
            </div>
        </div>

        <!-- TODO: исправить положение кнопок -->
        <!-- Нижние иконки -->
        </div>
        <div class="container-fluid p-0 mb-4">
            <div id="settings-button" data-bs-toggle="modal" data-bs-target="#info" class="d-flex align-items-center justify-content-center">
                <i id="instruction-icon" class="fa-solid fa-circle-info" style="color: #d3d3d3; font-size: 20px"></i>
            </div>
        </div>
</div>
</template>

:class="" 
<style scoped>
#instruction-icon:hover {
    cursor: pointer;
    color: white !important;
}
/* .button:hover {
    cursor: pointer;
    background-color: aquamarine;
} */

#leftside-panel {
    height: 100%;
    width: 4.5%;
    /* width: 95px; */
}

#background {
    /* height: 100%; */
    background-color: #1D1D22;
    /* background-color: #d3d3d3; */
}

.left-panel-button {
    height: 70px;
}

/* .left-panel-icon {
    height: 30px;
    width: 30px;
} */

.button:hover {
    background-color: #2B2B31;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    cursor: pointer;
}

.button.active {
    background-color: #2B2B31;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.button:hover i {
    color: white !important;
}

.button.active i {
    color: white !important;
}
</style>