import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "container-fluid p-0",
  id: "root"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_leftside_panel = _resolveComponent("leftside-panel")!
  const _component_additional_section = _resolveComponent("additional-section")!
  const _component_main_section = _resolveComponent("main-section")!
  const _component_rightside_section = _resolveComponent("rightside-section")!
  const _component_info_modal = _resolveComponent("info-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_leftside_panel, {
      selectedSection: $setup.selectedSection,
      onSelectSection: $setup.selectSection
    }, null, 8, ["selectedSection", "onSelectSection"]),
    _createVNode(_component_additional_section, {
      onRenameConversion: $setup.renameConversion,
      onRenameTemplate: $setup.renameTemplate,
      onRenameChat: $setup.renameChat,
      onDeleteConversion: $setup.deleteConversion,
      onSelectConversion: $setup.selectConversion,
      onSelectTemplate: $setup.selectTemplate,
      onDeleteTemplate: $setup.deleteTemplate,
      elements_of_additional: $setup.elements_of_additional,
      onDeleteChat: $setup.deleteChat,
      onSelectChat: $setup.selectChat,
      onAddChat: $setup.addChat,
      onAddTemplate: $setup.addTemplate,
      onAddConversion: $setup.addConversion,
      selectedSection: $setup.selectedSection
    }, null, 8, ["onRenameConversion", "onRenameTemplate", "onRenameChat", "onDeleteConversion", "onSelectConversion", "onSelectTemplate", "onDeleteTemplate", "elements_of_additional", "onDeleteChat", "onSelectChat", "onAddChat", "onAddTemplate", "onAddConversion", "selectedSection"]),
    _createVNode(_component_main_section, {
      needHistoryRef: $setup.needHistoryRef,
      onSetCustomTechnic: $setup.setCustomTechnic,
      onChangeConvert: $setup.changeConvert,
      onCallConvert: $setup.callConvert,
      onSaveTemplate: $setup.saveTemplate,
      currentTechnic: $setup.currentTechnic,
      subjectAreaNew: $setup.subjectAreaNew,
      templateRef: $setup.templateRef,
      selectedSection: $setup.selectedSection,
      systemPrompt: $setup.systemPromptNew,
      llm: $setup.llm,
      elements_of_section: $setup.elements_of_additional[$setup.selectedSection],
      elements_of_additional: $setup.elements_of_additional,
      textToConvert: $setup.textToConvert
    }, null, 8, ["needHistoryRef", "onSetCustomTechnic", "onChangeConvert", "onCallConvert", "onSaveTemplate", "currentTechnic", "subjectAreaNew", "templateRef", "selectedSection", "systemPrompt", "llm", "elements_of_section", "elements_of_additional", "textToConvert"]),
    _createVNode(_component_rightside_section, {
      onRememberHistory: $setup.rememberHistory,
      onUpdateContext: $setup.updateContext,
      onSetTechnic: $setup.setTechnic,
      onUpdateSubjectArea: $setup.updateSubjectArea,
      selectedSection: $setup.selectedSection,
      onUpdateSystemPrompt: $setup.updateSystemPrompt,
      onUpdateInputs: $setup.updateInputs,
      onSelectLLM: $setup.selectLLM,
      elements_of_additional: $setup.elements_of_additional,
      needSpinner: $setup.needSpinner,
      customTechnic: $setup.currentTechnic
    }, null, 8, ["onRememberHistory", "onUpdateContext", "onSetTechnic", "onUpdateSubjectArea", "selectedSection", "onUpdateSystemPrompt", "onUpdateInputs", "onSelectLLM", "elements_of_additional", "needSpinner", "customTechnic"]),
    _createVNode(_component_info_modal, { showModal: $setup.showModal }, null, 8, ["showModal"])
  ]))
}