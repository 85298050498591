<script lang="ts">
import { ref , defineComponent , watch, nextTick } from 'vue';
import axios from 'axios';
import ShortUniqueId from 'short-unique-id';
import SessionSection from '@/components/SessionSection.vue'
import TemplateSection from '@/components/TemplateSection.vue'
import ConversionSection from '@/components/ConversionSection.vue'

import {
  ElementsOfSection,
  Message,
  Prompt,
  Answer,
  Inputs,
  Template,
  ElementsOfAdditional,
  Conversion
} from '@/interfaces/interfaces';

    interface Props {
      selectedSection: string;
      systemPrompt: string;
      llm: string;
      elements_of_section: ElementsOfSection[] | Template[] | Conversion[]; // [{}, {}, {}, {}]
      templateRef: Template;
      currentTechnic: string;
      subjectAreaNew: string;
      textToConvert: string;
      elements_of_additional: ElementsOfAdditional;
      needHistoryRef: boolean
    }

export default defineComponent ({
    components: {
        SessionSection, TemplateSection, ConversionSection
    },
    props: {
        selectedSection: {
            type: String,
            required: true
        },
        systemPrompt: {
            type: String,
            required: true
        },
        llm: {
            type: String,
            required: true
        },
        elements_of_section: {
            type: Array as () => ElementsOfSection[] | Template[] | Conversion[],
            required: true
        },
        templateRef: {
            type: Object as () => Template,
            required: true
        },
        currentTechnic: {
            type: String,
            required: true
        },
        subjectAreaNew: {
            type: String,
            required: true
        },
        textToConvert: {
            type: String,
            required: true
        },
        elements_of_additional: {
            type: Object as () => ElementsOfAdditional,
            required: true
        },
        needHistoryRef: {
            type: Boolean,
            required: true
        }
    },

    emits: ['saveTemplate', 'callConvert', 'changeConvert', 'setCustomTechnic'],

    setup(props: Props, {emit}: any) {
        const uid = new ShortUniqueId({ length: 10 });
        let ending: { [key: number]: string } = {
            1: '',
            2: 'а',
            3: 'а',
            4: 'а',
            5: 'ов'
        }
        let textareaPrompt = ref<string>('')
        let prompt = <Prompt>({
            id: '',
            type: 'prompt',
            text: '',
            technic: ''
        });
        let answer = <Answer>({
            id: '',
            type: 'answer',
            text: '',
            technic: ''
        });
        let messagesBuffer = ref<Message[]>([])
        let textarea_template = ref<string>('Сгенерируй {количество вопросов} тестовых вопросов различных типов на тему "{тема}".')
        let currentTechnic = ref<string>('')
        let subjectArea = ref<string>('')
        let optionId = ref<string>('')
        let haveContext = ref<boolean>(false);
        let answer_to_convert = ref<string>('')
        let textToConvert = ref<string>('');
        let haveChat = ref<boolean>(false);
        let conversionId = ref<string>('');


        const sendPrompt = (llm: string) => {
            const chatId = getActiveChatId();
            const promptId = uid.rnd();
            const fullMessage = getFullMessage();
            const selectedTemplate = getSelectedTemplate() as Template;
            let messageWithTemplate = fullMessage;
            if (selectedTemplate) {
                messageWithTemplate = `${fullMessage}\n${selectedTemplate.context}\n${selectedTemplate.template_text}`;
            }
            prompt = {
                id: promptId,
                type: 'prompt',
                text: fullMessage,
                technic: selectedTemplate?.template_id || ''
            };
            messagesBuffer.value.push(prompt);
            textareaPrompt.value = '';
            sendPromptRequest(llm, chatId, messageWithTemplate, selectedTemplate?.template_id || '');
        };

        const getActiveChatId = () => {
            const activeElement = props.elements_of_section.find(obj => obj.active);
            return (activeElement as ElementsOfSection).chat_id;
        };

        const getFullMessage = (): string => {
            const userPrompt = textareaPrompt.value;
            return `${props.systemPrompt}\n${userPrompt}`;
        };

        const getSelectedTemplate = () => {
            const selectedTemplateId = optionId.value;
            if (selectedTemplateId && selectedTemplateId !== 'Шаблон не выбран') {
                return props.elements_of_additional['templates'].find(obj => (obj as Template).template_id === selectedTemplateId);
            }
            return null;
        };

        const sendPromptRequest = (llm: string, chatId: string, messageWithTemplate: string, templateId: string) => {
            console.log(`prompt: ${JSON.stringify(prompt)}`)
            
            axios.post('/api/chats/sendPrompt', {
                prompt: prompt,
                llm: llm,
                chat_id: chatId,
                messageWithTemplate: messageWithTemplate,
                template_id: templateId
            })
            .then(response => {
                answer = {
                    id: response.data.data.answer_id,
                    type: 'answer',
                    text: response.data.data.answer,
                    technic: response.data.data.technic
                };
                messagesBuffer.value.push(answer);
            })
            .catch(error => {
                console.error('Ошибка отправки промпта:', error);
            });
        };

        const saveTemplate = () => {
            emit('saveTemplate', textarea_template.value)
        }

        const updateTextarea_template = (subject_area: string) => {
            if (currentTechnic.value === 'custom') {
                textarea_template.value = `Сгенерируй {количество вопросов} тестовых вопросов различных типов на тему "${subject_area}".`
            } else {
                substitution_of_values(JSON.parse(props.templateRef.inputs_object))
            }
        }


        const questionTemplates: { [key: string]: { zeroshot: string, fewshot: string } } = {
            singleChoice: {
                zeroshot: `{question} вопросов типа "Единственный выбор" ({answer} вариантов ответов, указав один правильный ответ).`,
                fewshot: `{question} вопросов типа "Единственный выбор" ({answer} вариантов ответов, указав один правильный ответ). Пример вопроса:\n{Текст вопроса}\nа) {вариант_1}\nб) {вариант_2}\nв) {вариант_3}\nг) {вариант_4}\nПравильный ответ: {правильный_ответ_единственный_выбор}`
            },
            multiplyChoice: {
                zeroshot: `{question} вопросов типа "Множественный выбор" ({answer} вариантов ответа, указав несколько правильных ответа).`,
                fewshot: `{question} вопросов типа "Множественный выбор" ({answer} вариантов ответа, указав несколько правильных ответа). Пример вопроса:\n{Текст вопроса}\nа) {вариант_1}\nб) {вариант_2}\nв) {вариант_3}\nг) {вариант_4}\nПравильные ответы: {номера правильных ответов}`
            },
            yesNo: {
                zeroshot: `{question} вопросов типа “Да/Нет” (вопрос, который требует только два варианта ответа “Да” или “Нет”).`,
                fewshot: `{question} вопросов типа “Да/Нет” (вопрос, который требует только два варианта ответа “Да” или “Нет”). Пример вопроса:\n{Текст вопроса}\n1) Да\n2) Нет\nПравильный ответ: {номер правильного ответа}`
            },
            sort: {
                zeroshot: `{question} вопросов типа "Упорядочивание" ({answer} вариантов ответов, которые необходимо упорядочить в определенном порядке - напишите сразу в правильном порядке, каждый шаг пишите, начиная с дефиса "-", правильный ответ не нужен).`,
                fewshot: `{question} вопросов типа "Упорядочивание" ({answer} вариантов ответов, которые необходимо упорядочить в определенном порядке - напишите сразу в правильном порядке, каждый шаг пишите, начиная с дефиса "-", правильный ответ не нужен). Пример вопроса:\n{Текст вопроса}\n- {шаг_1}\n- {шаг_2}\n- {шаг_3}\n- {шаг_4}`
            },
            match: {
                zeroshot: `{question} вопросов типа "Соответствие" ({answer} варианта соответствия, которые нужно соединить с соответствующими правильными ответами).`,
                fewshot: `{question} вопросов типа "Соответствие" ({answer} варианта соответствия, которые нужно соединить с соответствующими правильными ответами). Пример вопроса:\n{Текст вопроса}\n- {пара_шаг_1}\n- {пара_шаг_2}\n- {пара_шаг_3}\n- {пара_шаг_4}\nПравильные пары соответствия: A) {текст варианта A}, B) {текст варианта B}, C) {текст варианта C}, D) {текст варианта D}`
            },
            manualEnter: {
                zeroshot: `{question} вопросов типа "Текстовый ответ" (требует {answer} правильный ответ, состоящий из одного или двух слов, укажи его).`,
                fewshot: `{question} вопросов типа "Текстовый ответ" (требует {answer} правильный ответ, состоящий из одного или двух слов, укажи его). Пример вопроса:\n{Текст вопроса}\nПравильный ответ: {правильный_ответ_1_слово}`
            }
        };


        const substitution_of_values = (object: Inputs) => {
            if (currentTechnic.value !== 'custom') {
                let sum_questions = 0;
                for (const key in object) {
                    sum_questions += object[key].question;
                }
                textarea_template.value = `Сгенерируй ${sum_questions} тестовых вопросов различных типов на тему "${subjectArea.value || '{тема}'}".\n`;

                const technic = currentTechnic.value === 'zeroshot' ? 'zeroshot' : 'fewshot';
                for (const key in object) {
                    if (object[key].question !== 0 && object[key].answer !== 0) {
                        const template = questionTemplates[key]?.[technic];
                        if (template) {
                            textarea_template.value += `\n${template.replace('{question}', object[key].question.toString())
                                                            .replace('{answer}', object[key].answer.toString())}\n`;
                        }
                    }
                }
            }
        };


        const selectOptionTemplate = (event: any) => {
            optionId.value = event.target.value
            if (optionId.value !== '' && optionId.value !== 'Шаблон не выбран') {
                let template = props.elements_of_additional['templates'].find(obj => (obj as Template).template_id === optionId.value) as Template;
                let context = template.context;
                haveContext.value = context !== '';
            } else {
                haveContext.value = false
            }
        }

        const callConvert = (answerText: string, message_id: string) => {
            emit('callConvert', answerText, message_id)
        }

        const changeConvert = (answerText: string, conversion_id: string) => {
            emit('changeConvert', answerText, conversion_id)
        }

        // Функция, которую вы хотите вызвать при изменении текста
        const handleTextareaChange = () => {
            currentTechnic.value = 'custom'
            emit('setCustomTechnic', 'custom')
        };


        watch(
            () => props.elements_of_section,
            () => {
                nextTick(()=> {
                    const activeChat = props.elements_of_section.find(chat => chat.active);
                    if (activeChat) {
                        messagesBuffer.value = (activeChat as ElementsOfSection).dialog;
                        haveChat.value = true
                    } else {
                        messagesBuffer.value = []
                        haveChat.value = false
                    }
                    const activeTemplate = props.elements_of_section.find(template => template.active);
                    if (activeTemplate) {
                        textarea_template.value = (activeTemplate as Template).template_text
                        currentTechnic.value = (activeTemplate as Template).technic
                        subjectArea.value = (activeTemplate as Template).subject_area
                    } else {
                        textarea_template.value = ''
                    }
                    const activeConversion = props.elements_of_section.find(conversion => conversion.active);
                    if (activeConversion) {
                        answer_to_convert.value = (activeConversion as Conversion).answer
                        textToConvert.value = answer_to_convert.value
                        conversionId.value = (activeConversion as Conversion).conversion_id

                    } else {
                        textToConvert.value = ''
                    }
                })
            },
            { deep: true }
        )


        watch(
            () => props.templateRef,
            () => {
                nextTick(()=> {
                    substitution_of_values(JSON.parse(props.templateRef.inputs_object))
                })
            },
            { deep: true }
        )

        watch(
            () => props.currentTechnic,
            () => {
                nextTick(()=> {
                    if (props.currentTechnic != '') {
                        currentTechnic.value = props.currentTechnic
                    }
                })
            }
        )

        watch(
            () => props.subjectAreaNew,
            () => {
                nextTick(()=> {
                    if (props.subjectAreaNew != '') {
                        subjectArea.value = props.subjectAreaNew
                        updateTextarea_template(subjectArea.value)
                    }
                })
            }
        )

        watch(
            () => props.textToConvert,
            (newValue) => {
                nextTick(() => {
                    textToConvert.value = newValue;
                });
            }
        );


        return { sendPrompt , textareaPrompt, handleTextareaChange, answer , prompt , messagesBuffer, saveTemplate, textarea_template, currentTechnic, selectOptionTemplate, haveContext, callConvert, changeConvert, answer_to_convert, textToConvert, haveChat, conversionId , optionId}
    }
})

</script>


<template>
    <!-- <session-section />
    <template-section />
    <conversion-section /> -->
<!-- Чат -->
<div v-if="selectedSection === 'chats'" class="container-fluid p-0 m-0" id="main-section-chats">
    <div  class="container-fluid p-0 d-flex flex-column justify-content-between" id="background">
        <div class="container-fluid p-0 d-flex flex-column" id="dialog">
            <div v-for="message in messagesBuffer" :key="message.id" class="prompt-answer container-fluid p-0">
                <div v-if="message.type === 'prompt'" class="shadow-lg container-fluid p-3 d-flex mt-4" id="user_prompt">
                    <div class="pe-4">
                        <i class="fa-solid fa-user" style="color: #d3d3d3; font-size: 20px;"></i>
                        <p>Вы</p>
                    </div>
                    <div style="overflow-y: auto;">
                        <p v-if="message.technic === ''">{{ message.text }}</p>
                        <p v-if="message.technic !== ''"><i><u>Учтён текст шаблона.</u></i><br>{{ message.text }}</p>
                    </div>
                    </div>
                    <div v-if="message.type === 'prompt' && messagesBuffer.indexOf(message) === messagesBuffer.length - 1" class="d-flex justify-content-center mt-4">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                <div v-if="message.type === 'answer'" class="shadow-lg container-fluid p-3 d-flex mt-4" id="llm_answer">
                    <div class="pe-4">
                        <i class="fa-solid fa-robot" style="color: #d3d3d3; font-size: 18px;"></i>
                    </div>
                    <div class="container-fluid d-flex flex-column justify-content-between p-0">
                        <div class="container-fluid p-0">
                            <p style="white-space: pre-line;">{{ message.text }}</p>
                        </div>
                        <div class="d-flex justify-content-end p-0">
                            <div id="button_conversion_in_chat" @click="callConvert(message.text, message.id)" class="p-0 m-0">
                                <p class="p-2 text-center m-0 text-truncate"><b>TEXT → XML</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid p-0 d-flex align-items-center justify-content-center position-relative" id="request_block">
            <div class="textarea d-flex shadow-lg align-items-center border-0 ps-4 pe-5">
                <div class="items_of_settings  d-flex flex-column p-0 align-items-center position-relative" id="select_template">
                    <select class="form-select form-select-sm" aria-label=".form-select-lg example" v-model="optionId" @change="selectOptionTemplate($event)">
                        <option value="">Шаблон не выбран</option>
                        <option v-for="(option, index) in elements_of_additional['templates']" :key="index" :value="(option as Template).template_id"> {{ (option as Template).name }}</option>
                    </select>
                    <i class="fa-solid fa-puzzle-piece position-absolute" style="color: black;"></i>
                </div>
                <textarea :placeholder="haveChat === false ? 'Создайте новую или выберите существующую сессию генерации' : (llm === 'empty' ? 'Выберите LLM' : 'Введите промпт...')" class="container-fluid d-flex align-items-center pt-4" id="textarea_for_send" v-model="textareaPrompt"></textarea>
            </div>
            <i @click="sendPrompt(llm)" :class="llm === 'empty' ? 'disabled' : (haveChat === false ? 'disabled' : '')" class="fa-solid fa-paper-plane position-absolute"></i>
            <div v-if="haveContext === true" id="info_about_context" class="d-flex position-absolute">
                <div class="d-flex justify-content-center align-items-center"><i class="fa-solid fa-circle-info mb-0 me-1" style="color: white;"></i></div>
                <div class="d-flex justify-content-center align-items-center"><p class="m-0 ms-1"><i>Для данного шаблона установлен контекст</i></p></div>
            </div>
        </div>
    </div>
</div>
<!-- Чат -->


<!-- Шаблоны -->
<div v-if="selectedSection === 'templates'" class="container-fluid p-0 m-0" id="main-section-templates">
    <div class="container-fluid p-0 d-flex flex-column justify-content-between" id="background">
        <div class="container-fluid p-0 d-flex flex-column justify-content-center" id="template">
            <div class="template_block container-fluid p-0 mb">
                <div class="container-fluid p-0 h-100">
                    <div id="header_template" class="p-2 container-fluid p-0" >
                        Шаблон промпта
                    </div>
                    <textarea :placeholder="textarea_template === '' ? 'Создайте новый или выберите существующий шаблон' : ''" v-model="textarea_template" id="textarea-for-template" class="container-fluid p-3 pb-5" @input="handleTextareaChange"></textarea>
                    <div class="d-flex justify-content-center p-0">
                        <div id="button-save" class="container-fluid p-0 mt-5">
                            <p @click="saveTemplate" class="p-2 text-center m-0">Сохранить шаблон</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Шаблоны -->


<!-- Конвертация -->
<div v-if="selectedSection === 'conversions'" class="container-fluid p-0 m-0" id="main-section-conversion">
    <div class="container-fluid p-0 d-flex flex-column justify-content-between" id="background">
        <div class="container-fluid d-flex align-items-center" id="header-of-conversion">
            <div class="d-flex" id="logo">
                <div id="txt">txt</div>
                <div id="result_format">xml</div>
            </div>
            <div class="ps-3"><i class="fa-solid fa-circle-info" style="color: gray;"></i></div>
            <div class="ps-3" style="color: white; font-weight: bold;">Конвертация</div>
        </div>
        <div class="container-fluid p-0 d-flex flex-column justify-content-center" id="conversion">
            <div class="conversion_block container-fluid p-0 mb">
                <div class="container-fluid p-0 h-100">
                    <textarea id="textarea-for-conversion" class="container-fluid p-3 pb-5" v-model="textToConvert"></textarea>
                    <div class="d-flex justify-content-center p-0">
                        <div id="button-conversion" class="container-fluid p-0 mt-5" @click="changeConvert(textToConvert, conversionId)">
                            <p class="p-2 text-center m-0">Конвертировать</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Конвертация -->


</template>


<style scoped>
#select_template select {
    background-color: #779EEA;
    border-color: #4574CD;
    color: rgb(3, 3, 3);
    /* width: 150px; */
    padding-left: 40px;
}

#select_template select option {
    background-color: #B2C7EF;
    color: rgb(3, 3, 3);
}

.fa-puzzle-piece {
    left:0;
    padding-top: 7px;
    margin-left: 15px;
}

i.disabled {
    pointer-events: none;
    opacity: 0.5;
}

#textarea_for_send {
    background-color: #4A4A56;
    resize: none;
    border: none;
    color: rgb(219, 219, 219);
}

#textarea_for_send:focus {
    outline: none;
}

#result_format {
    background-color: #E9F557;
    height: 100%;
    width: 50%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: black;
    text-align: center;
    font-weight: bold;
}

#txt {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #50009F;
    text-align: center;
    height: 100%;
    width: 50%;
    color: white;
    font-weight: bold;
}

#logo {
    width: 12%;
    height: 60%;
}

#header-of-conversion {
    height: 40px;
    background-color: black;
}

#header_template {
    background-color: #292943;
    color: white;
}

#button-save {
    width: 35%;
    background-color: #71A876;
    color: rgb(235, 235, 235);
    border-radius: 20px;
    transition: 0.3s;
}

#button-save:hover {
    background-color: #83c088;
    color: white;
    cursor: pointer;
}

#button-conversion {
    width: 35%;
    background-color: #eeff2e;
    color: rgb(0, 0, 0);
    border-radius: 20px;
    transition: 0.3s;
}

#button-conversion:hover {
    background-color: #f3ff6b;
    color: rgb(0, 0, 0);
    cursor: pointer;
}

#button_conversion_in_chat {
    width: 20%;
    background-color: #4e4b83;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    transition: 0.3s;
}

#button_conversion_in_chat:hover {
    background-color: rgb(105, 100, 173);
    color: rgb(255, 255, 255);
    cursor: pointer;
}

#button_conversion_in_chat_disabled {
    font-size: 9pt;
    background-color: #4c4b5a;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    transition: 0.3s;
}


#textarea-for-template {
    resize: none;
    height: 90%;
    background-color: #3D3D4B;
    color: white;
    border: none;
}

#textarea-for-template:focus {
    outline: none;
}

#textarea-for-conversion {
    resize: none;
    height: 90%;
    background-color: #232328;
    border-radius: 10px;
    color: white;
    border: none;
}

#textarea-for-conversion:focus {
    outline: none;
}
/* TODO: Изменять размер этой секции и дочерние элементы взависимости от ширины экрана.
    Разрешить уменьшать до определенного размера.
    использовать классы .d-* для определения точки останова, при которой элемент будет изменять свое поведение.
    Например, класс .d-none скрывает элемент на всех устройствах,
    класс .d-sm-block отображает элемент на устройствах шириной от 576px и выше,
    а класс .d-lg-none скрывает элемент на устройствах шириной от 992px и выше.
    + использовать @media */
#main-section-chats {
    height: 100%;
    width: 76.5%;
    /* flex-grow: 1 */
}

#main-section-templates {
    height: 100%;
    width: 51%;
    /* flex-grow: 1 */
    /* flex: 1 1 auto */
}

#main-section-conversion {
    height: 100%;
    width: 43.5%;
    /* flex-grow: 1 */
    /* flex: 1 1 auto */
}

#background {
    height: 100%;
    /* background-color: #2E2E36; */
    background: radial-gradient(#37374b, #31313b);
}

#dialog {
    height: 85%;
    overflow-y: auto;
}

#template {
    height: 100%;
}

#conversion {
    height: 100%;
}

#request_block {
    height: 15%;
    width: 65%;
}

.textarea {
  background-color: #4A4A56;
  border-radius: 50px;
  resize: none;
  width: 100%;
  /* white-space: pre-wrap;
  word-wrap: break-word; */
  min-height: 35%; /* Минимальная высота */
  max-height: 80%; /* Максимальная высота */
  overflow-x: auto;
  overflow-y: auto;
  color:rgb(230, 230, 226);
}

.textarea:focus {
    outline: none;
}

#user_prompt {
    background-color: #3D3D4B;
    height: 140px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

#llm_answer {
    background-color: #3A3A54;
    min-height: 140px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.prompt-answer {
    width: 65%;
    color: white
}

.template_block {
    width: 70%;
    height: 90%;
}

.conversion_block {
    width: 70%;
    height: 90%;
}

.fa-paper-plane {
    right:0;
    margin-right: 25px;
    cursor: pointer;
    color: rgb(41, 41, 41);
    transition: 0.3s;
    font-size: 25px;
}

#info_about_context {
    left: 0;
    margin-top: 110px;
    margin-left: 35px;
    color: rgb(201, 201, 201);
}

.fa-paper-plane:hover {
    color:rgb(176, 116, 245);
}
</style>
