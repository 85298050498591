<script lang="ts">
import LeftsidePanel from '@/components/LeftsidePanel.vue'
import AdditionalSection from '@/components/AdditionalSection.vue'
import MainSection from '@/components/MainSection.vue'
import RightsideSection from '@/components/RightsideSection.vue'
import InfoModal from '@/components/InfoModal.vue'
import { ref, onMounted } from 'vue';
import axios from 'axios';
import ShortUniqueId from 'short-unique-id';

import {
  Conversion,
  ElementsOfAdditional,
  Inputs,
  Template
} from '@/interfaces/interfaces';

export default {
  components: {
    LeftsidePanel, AdditionalSection, MainSection, RightsideSection, InfoModal
  },
  setup() {
    const uid = new ShortUniqueId({ length: 10 });
    let selectedSection = ref('chats'); // Начальная активная секция
    let elements_of_additional = ref<ElementsOfAdditional>({
      'chats': [],
      'templates': [],
      'conversions': []
    });
    let systemPromptNew = ref<string>('');
    let count_template = 0;
    let count_conversion = 0;
    let llm = ref<string>('empty')
    let currentTechnic = ref('')
    let subjectAreaNew = ref('')
    let contextNew = ref('')
    let optionId = ref('')
    let showModal = ref(false)
    let templateRef = ref<Template>({
        template_id: '',
        name: '',
        question_type: '',
        inputs_object: '{"singleChoice":{"question":0,"answer":2},"multiplyChoice":{"question":0,"answer":2},"yesNo":{"question":0,"answer":2},"sort":{"question":0,"answer":2},"match":{"question":0,"answer":2},"manualEnter":{"question":0,"answer":1}}',
        subject_area: '',
        technic: '',
        context: '',
        template_text: '',
        active: true
    })
    let textToConvert = ref('')
    let needSpinner = ref<boolean>(false)
    let renamedTemplate = ref<string>('')
    let needHistoryRef = ref<boolean>(false)


    const selectSection = (section: string) => {
      axios.get(`/api/${section}/getDataOfElements`)
      .then(response => {
        if (response.data.data[section].length != 0) {
          elements_of_additional.value[`${section}`] = response.data.data[`${section}`]
          selectedSection.value = section
        } else {
          selectedSection.value = section
        }
      })
      .catch(error => {
        console.log('Error: ', error);
      });
    }


    const addChat = (section: string) => {
      let id = uid.rnd();
      let chat = {
        chat_id: id,
        name: `Сессия генерации`,
        active: true,
        dialog: []
      }
      // elements_of_additional.value[section].push(chat); // Это убрать и принимать с сервака по идее
      axios.post('/api/chats/addChat', {chat})
      .then(response => {
        console.log('Чат добавлен на сервере')
        elements_of_additional.value[section] = response.data.data.chats
        // console.log(`chats_db = ${response.data.data.chats_db}`)
      })
      .catch(error => {
        console.log('Error: ', error)
      })
    }


    // Добавить новый чат с нулевыми параметрами
    const addTemplate = (section: string) => {
      let id = uid.rnd();
      console.log(id)

      const template = {
        template_id: id,
        name: '',
        question_type: '',
        inputs_object: '{"singleChoice":{"question":0,"answer":2},"multiplyChoice":{"question":0,"answer":2},"yesNo":{"question":0,"answer":2},"sort":{"question":0,"answer":2},"match":{"question":0,"answer":2},"manualEnter":{"question":0,"answer":1}}',
        subject_area: '',
        technic: 'custom',
        context: '',
        template_text: 'Сгенерируй {количество вопросов} тестовых вопросов различных типов на тему "{тема}".',
        active: true
      };

      axios.post('/api/templates/addTemplate', template)
        .then(response => {
          console.log('Шаблон добавлен на сервер')
          elements_of_additional.value[section] = response.data.data.templates
          subjectAreaNew.value = ''
          renamedTemplate.value = ''
          console.log('ТУТ ЧТО ЛИ ОБНУЛИЛСЯ 1 ?')
          contextNew.value = ''
          // console.log(`response.data.data.templates = ${JSON.stringify(response.data.data.templates[0].inputs_object)}`)
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    }



    const addConversion = async (section: string) => {
      console.log('Добавил элемент конвертации')
      let id = uid.rnd();
      let conversion = {
        conversion_id: id,
        name: 'Конвертация',
        answer: '',
        result: '',
        active: true
      }
      let response = await axios.post('/api/conversions/addConversion', conversion)
        .then(response => {
          console.log('Элемент конвертации добавлен на сервер')
          elements_of_additional.value[section] = response.data.data.conversions
          console.log('1')
          // subjectAreaNew.value = ''
          // contextNew.value = ''
          // console.log(`response.data.data.templates = ${JSON.stringify(response.data.data.templates[0].inputs_object)}`)
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    }


    const updateSystemPrompt = (systemPrompt: string) => {
      systemPromptNew.value = systemPrompt
    }

    // При обновлении input'ов принимаем и инпуты и установленную технику
    const updateInputs = (inputs: Inputs, technic: string) => {
      if (technic != '') {
        let active_template = elements_of_additional.value['templates'].find(obj => obj.active);
        templateRef.value.template_id = (active_template as Template)?.template_id;
        templateRef.value.inputs_object = JSON.stringify(inputs);
        templateRef.value.technic = technic
      }
    }

    const setTechnic = (technic: string) => {
      currentTechnic.value = technic
      console.log('1, currentTechnic Parent = ' + currentTechnic.value)
    }

    const updateSubjectArea = (subjectArea: string) => {
      subjectAreaNew.value = subjectArea
    }

    const selectChat = (chat_id: string, section: string) => {
      axios.post('/api/chats/selectChat', {chat_id})
          .then(response => {
            // Обновить все чаты, при выборе чата (т.к. на сервере изменились данные)
            elements_of_additional.value[section] = response.data.data
          })
          .catch(error => {
            console.log('Error: ', error)
          })
    }

    const selectTemplate = (template_id: string, section: string) => {
      axios.post('/api/templates/selectTemplate', {template_id})
      .then(response => {
        elements_of_additional.value[section] = response.data.data
        // currentTechnic.value = ''
          // renamedTemplate.value = ''
          // console.log('ТУТ ЧТО ЛИ ОБНУЛИЛСЯ 2 ?')
          subjectAreaNew.value = ''
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    }

    const selectConversion = (conversion_id: string, section: string) => {
      axios.post('/api/conversions/selectConversion', {conversion_id})
        .then(response => {
          elements_of_additional.value[section] = response.data.data
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    }


    const deleteChat = (chat_id: string, section: string) => {
      axios.post('/api/chats/deleteChat', {chat_id})
        .then(response => {
          elements_of_additional.value[section] = response.data.data
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    }

    const renameChat = (chat_id: string, name: string) => {
      axios.patch('/api/chats/renameChat', {chat_id, name})
        .then(response => {
          elements_of_additional.value['chats'] = response.data.data
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    }

    const renameTemplate = (template_id: string, name: string) => {
      renamedTemplate.value = name
      axios.patch('/api/templates/renameTemplate', {template_id, name})
        .then(response => {
          elements_of_additional.value['templates'] = response.data.data
          renamedTemplate.value = ''
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    }

    const renameConversion = (conversion_id: string, name: string) => {
      axios.patch('/api/conversions/renameConversion', {conversion_id, name})
        .then(response => {
          elements_of_additional.value['conversions'] = response.data.data
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    }

    const deleteTemplate = (template_id: string, section: string) => {
      axios.post('/api/templates/deleteTemplate', {template_id})
        .then(response => {
          elements_of_additional.value[section] = response.data.data
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    }

    const deleteConversion = (conversion_id: string, section: string) => {
      axios.post('/api/conversions/deleteConversion', {conversion_id})
      .then(response => {
          textToConvert.value = ''
          elements_of_additional.value['conversions'] = response.data.data
        })
        .catch(error => {
          console.log('Error: ', error)
        })
    }


    const selectLLM = (selectedLLM: string) => {
      console.log(`Selected LLM: ${llm}`)
      llm.value = selectedLLM
      // TODO: Хранить выбор в localstorage
    }

    const updateContext = (context: string) => {
      contextNew.value = context
      console.log('Передан контекст в корневой и равен:', contextNew.value)
    }

    // const selectOptionTemplate = (option: string) => {
    //   optionId.value = option
    //   console.log('Выбрал опцию:', optionId.value)
    // }
    const saveTemplate = (template_textarea: string) => {
      templateRef.value.template_text = template_textarea
      templateRef.value.subject_area = subjectAreaNew.value
      templateRef.value.name = renamedTemplate.value
      templateRef.value.context = contextNew.value
      axios.post('/api/templates/saveTemplateObject', templateRef.value)
            .then(response => {
              elements_of_additional.value['templates'] = response.data.data
            })
            .catch(error => {
                console.log('Error: ', error);
            })
    }

    const callConvert = async (answerText: string, message_id: string) => {
      selectedSection.value = 'conversions'
      // создать новый элемент конвертации, чтобы заполнять поля там же
      await addConversion('conversions')
      needSpinner.value = true
      const activeConvertion = elements_of_additional.value['conversions'].find(conversion => conversion.active);
      let conversion_id = (activeConvertion as Conversion).conversion_id
      textToConvert.value = answerText
      axios.post('/api/conversions/convertText', {answer: textToConvert.value, conversion_id: conversion_id})
        .then(response => {
          elements_of_additional.value['conversions'] = response.data.data
          needSpinner.value = false
          })
          .catch(error => {
            console.log('Error: ', error);
            needSpinner.value = false
        })
    }


    const changeConvert = (answerText: string, conversion_id: string) => {
      needSpinner.value = true
      axios.post('/api/conversions/convertText', {answer: answerText, conversion_id: conversion_id})
        .then(response => {
          elements_of_additional.value['conversions'] = response.data.data
          needSpinner.value = false
          })
          .catch(error => {
            console.log('Error: ', error);
            needSpinner.value = false
        })
    }

    const setCustomTechnic = () => {
      currentTechnic.value = 'custom'
    }

    const rememberHistory = (needHistory: boolean) => {
      needHistoryRef.value = needHistory
    }


    onMounted(() => {
      // TODO: Проверять текущий раздел
      axios.get('/api/chats/getDataOfElements')
        .then(response => {
          if (response.data.data.chats.length != 0) {
            // count_chat = response.data.data.chats.length + 1
            elements_of_additional.value['chats'] = response.data.data.chats
          }
        })
        .catch(error => {
          console.log('Error: ', error);
        });
        axios.get('/api/templates/getDataOfElements')
        .then(response => {
          if (response.data.data.templates.length != 0) {
            // count_chat = response.data.data.chats.length + 1
            elements_of_additional.value['templates'] = response.data.data.templates
          }
        })
        .catch(error => {
          console.log('Error: ', error);
        });
        axios.get('/api/conversions/getDataOfElements')
        .then(response => {
          if (response.data.data.conversions.length != 0) {
            elements_of_additional.value['conversions'] = response.data.data.conversions
          }
        })
        .catch(error => {
          console.log('Error: ', error);
        });
        // console.log(`elements_of_additionsl.conversions: `, elements_of_additional.value['conversions'])
        showModal.value = true
    });


    return {
      selectSection,
      selectedSection,
      elements_of_additional,
      addChat,
      updateSystemPrompt,
      systemPromptNew,
      addTemplate,
      addConversion,
      selectChat,
      deleteChat,
      selectLLM,
      llm,
      deleteTemplate,
      selectTemplate,
      updateInputs,
      setTechnic,
      templateRef,
      saveTemplate,
      currentTechnic,
      updateSubjectArea,
      subjectAreaNew,
      updateContext,
      showModal,
      selectConversion,
      deleteConversion,
      callConvert,
      textToConvert,
      needSpinner,
      changeConvert,
      setCustomTechnic,
      renameChat,
      renameTemplate,
      renameConversion,
      rememberHistory,
      needHistoryRef
      // selectOptionTemplate,
      // optionId
    }
  }
}
</script>


<template>
<div class="container-fluid p-0" id="root">
  <!-- Левая боковая панель -->
  <leftside-panel :selectedSection='selectedSection' @selectSection="selectSection"/>
  <!-- Дополнительная секция -->
  <additional-section @renameConversion="renameConversion" @renameTemplate="renameTemplate" @renameChat="renameChat" @deleteConversion="deleteConversion" @selectConversion="selectConversion" @selectTemplate="selectTemplate" @deleteTemplate="deleteTemplate" :elements_of_additional="elements_of_additional" @deleteChat="deleteChat" @selectChat="selectChat" @addChat="addChat" @addTemplate="addTemplate" @addConversion="addConversion" :selectedSection='selectedSection'/>
  <!-- Основная секция -->
  <main-section :needHistoryRef="needHistoryRef" @setCustomTechnic="setCustomTechnic" @changeConvert="changeConvert" @callConvert="callConvert" @saveTemplate="saveTemplate" :currentTechnic="currentTechnic" :subjectAreaNew="subjectAreaNew" :templateRef="templateRef" :selectedSection='selectedSection' :systemPrompt='systemPromptNew' :llm="llm" :elements_of_section="elements_of_additional[selectedSection]" :elements_of_additional="elements_of_additional" :textToConvert="textToConvert"/>
  <!-- Правая боковая секция-->
  <rightside-section @rememberHistory="rememberHistory" @updateContext="updateContext" @setTechnic="setTechnic" @updateSubjectArea="updateSubjectArea" :selectedSection='selectedSection' @updateSystemPrompt="updateSystemPrompt" @updateInputs="updateInputs" @selectLLM="selectLLM" :elements_of_additional="elements_of_additional" :needSpinner="needSpinner" :customTechnic="currentTechnic"/>
  <info-modal :showModal="showModal"/>
</div>
</template>


<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html, body {
  height: 100%;
  width: 100%;
}

#app {
  height: 100%;
  width: 100%;
}

/* TODO: сделать гибким установку ширины дочерних элементов
   чтобы их процентовка автоматически изменялась, при изменении ширин других */
#root {
  height: 100%;
  background-color: #1D1D22;
  display: flex;
}
</style>
